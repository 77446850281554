//import { useTable } from "react-table";
import { Link } from "react-router-dom";
import { KTSVG } from "../../base/helpers";
//import { useMemo } from "react";
import { TableProps } from "./model/TableProps";
import { CApprovalModal } from "../CApprovalModal/CApprovalModal";
import { useEffect, useMemo, useState } from "react";
import { Icons } from "../../shared/constant/icon/Icons";

const CTable = <T,>({ columns, data, options, pagination, onDelete, onEdit }: TableProps<T>) => {

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<number>();
  const [deleteRowName, setDeleteRowName] = useState<string>('');
  const [sortedData, setSortedData] = useState([]);

  const deleteModalOptions = useMemo(() => ({
    header: 'Silme Onayı',
    text: deleteRowId
      ? `${deleteRowId} numaralı kayıt ${deleteRowName} ve altındaki kayıtlar silinecektir, onaylıyor musunuz?`
      : 'Kayıt ve altındaki kayıtlar silinecektir, onaylıyor musunuz?',
    approval_button_title: 'Onayla',
    cancel_button_title: 'Vazgeç',
  }), [deleteRowId]);

  const deleteModalClosed = () => {
    setShowDeleteModal(false);
    setDeleteRowId(undefined);
    setDeleteRowName('');
  };

  const deleteModalApproved = () => {
    setShowDeleteModal(false);
    if (onDelete && deleteRowId) {
      onDelete(deleteRowId);
    }
  };

  /*useEffect(() => {

    if (Array.isArray(data) && data.length > 0) {
      let sorted = [...data];

      const tmpColumn = options?.dataSortingColumn as string ?? "created_date";

      /*if(tmpColumn && (typeof tmpColumn === 'string' || typeof tmpColumn === 'number')){
        if (options?.dataSorting === "asc") {
          sorted.sort((a, b) => a[tmpColumn] - b[tmpColumn]);
        } else {
          sorted.sort((a, b) => b[tmpColumn] - a[tmpColumn]);
        }
      }* /

      //setSortedData(sorted);
    }

  }, [data]);*/

  return (
    <>
      <div className={`card ${options.className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">{options.header}</span>
            {/* <span className="text-muted mt-1 fw-semibold fs-7">
              {options.description}
            </span> */}
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-striped table-sm table-rounded table-hover border border-gray-400 table-row-bordered table-row-gray-100 align-middle gy-5 gs-7">
              {/* begin::Table head */}
              <caption>{options.description}</caption>
              <thead>
                <tr className="fw-bold text-muted fs-6 text-gray-800">
                  <th className="min-w-120px" scope="col">#</th>
                  {columns.map((column: any, index: any) => {
                    return (
                      <th
                        key={"column" + index}
                        className={`${(column.width ? `min-w-'${column.width}px` : '') + (column.classname ?? '')}`}
                        scope="col"
                      >
                        {column.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item: any, rowIndex: any) => {
                    return (
                      <tr key={"tr" + rowIndex}>
                        <th key={`cell-rowIndex-th-${rowIndex}`} scope="row" className="table-success">
                          {rowIndex + 1}
                        </th>
                        {columns.map((column, colIndex) => (
                          <td key={`cell-td-${rowIndex}-${colIndex}`} hidden={column.key !== undefined ? false : true}>
                            <div
                              className={`${(column.classname ?? '') + ' ' +
                                (column.colorkey ? item[column.colorkey] + ' ' : '') +
                                (column.color ?? '') + ' ' +
                                (column.bold ? 'fw-bolder' : '')
                                }`}
                            >
                              {item[column.key] as React.ReactNode}
                            </div>
                          </td>
                        ))}
                        {options.icon &&
                          <td className="table-primary justify-content-end flex-shrink-0" key={"td3-" + rowIndex}>
                            <Link
                              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                              to={`/${options.navigateURL +
                                "/" +
                                item[options.navigateKey]
                                }`}>
                              <KTSVG
                                path={`${options.icon}`}
                                className="svg-icon-2"
                              />
                            </Link>
                            {onEdit && (<>
                              <Link
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                to={`/${options.navigateEditUrl}/${item[options.navigateEditKey]}`}>
                                <KTSVG
                                  path={Icons.Edit}
                                  className="svg-icon-2"
                                />
                              </Link>
                            </>)}
                            {onDelete && (<>
                              <a
                                href="#"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  setDeleteRowId(item.id as number);
                                  const tmpRowName = options?.deleteRowName ? item[options.deleteRowName] : item?.title;
                                  setDeleteRowName(tmpRowName);
                                  setShowDeleteModal(true);
                                }}
                              >
                                <KTSVG
                                  path={Icons.Remove}
                                  className="svg-icon-3"
                                />
                              </a></>)}
                          </td>
                        }
                      </tr>
                    );
                  })}
              </tbody>
              {/* end::Table body */}
              <tfoot>
                <tr key="column-footer-count-tr">
                  <th key="column-footer-count-th" colSpan={3}>Toplam Kayıt Sayısı: {data?.length ?? 0}</th>
                </tr>
              </tfoot>
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        <ul className="pagination">
          <li className="page-item previous disabled">
            <a href="#" className="page-link">
              <i className="previous"></i>
            </a>
          </li>
          {data &&
            data.length > 0 &&
            Array.from({ length: pagination?.totalPages || 1 }, (_, index) => {
              return (
                <li
                  className={`page-item ${pagination?.currentPage === 1 ? '' : 'active'}`}
                  key={`li-${index}`}
                >
                  <a href="#" className="page-link">
                    {index + 1}
                  </a>
                </li>
              );
            })}

          <li className="page-item next">
            <a href="#" className="page-link">
              <i className="next"></i>
            </a>
          </li>
        </ul>
      </div>

      <CApprovalModal
        show={showDeleteModal}
        options={deleteModalOptions}
        onClosedClick={() => deleteModalClosed()}
        onApprovedClick={() => deleteModalApproved()}
      />

    </>
  );

}

export default CTable;
