import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DemandGenericDataService } from "../../../services/demand-generic-data/demand-generic-data-service";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { GenericDataModel } from "../../../models";
import { Icons } from "../../../shared/constant/icon/Icons";
import CTable from "../../../components/CTable/CTable";
import { PaginationProps } from "../../../components/CTable/model/PaginationProps";


export function GenericDataDetail() {

    const { id } = useParams();
    const { getAllSubGenericDataById } = DemandGenericDataService();
    const [genericDataDetail, setGenericDataDetail] = useState<any>([]);
    const [pagination, setPagination] = useState<PaginationProps>();

    useEffect(() => {
        const getGenericDataDetail = async (id: any) => {
            const data = await getAllSubGenericDataById(1, 1, { "id": id });
            setGenericDataDetail(data);
        };

        getGenericDataDetail(id);

        const paginationData: PaginationProps = {
            currentPage: 1,
            totalPages: 1
        };

        setPagination(paginationData);
    }, [id]);

    const columns: TableColumn<GenericDataModel>[] = [
        { label: "Key", key: "id", width: 150 },
        { label: "Adı", key: "title", width: 150 },
        { label: "Açıklaması", key: "description", width: 200 },
        { label: "Sıralaması", key: "order", width: 200 },
        { label: "Değer", key: "value", width: 200 },
        { label: "Oluşturulma Tarihi", key: "created_date", width: 150 },
        { label: "Güncellenme Tarihi", key: "updated_date", width: 150 },
        { label: "Aksiyon", key: undefined, width: 130 },
    ];

    const tableOptions = {
        header: "Veri Tipi Detayları",
        description: "Veri Tipi Detay Listesi",
        columns: columns,
        data: genericDataDetail,
        icon: Icons.Edit
    };

    const handleDelete = async (id: number) => {
        try {

            /*const result = await deleteDemandType(id);
            if (result && result.status === 200) {
              setData((prevData) => prevData?.filter((item) => item.id !== id));
            } else {
              alert('Hata: ' + result?.messages);
              console.error("Error deleting data:", result.messages);
            }*/

        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    return (
        <>
            <CTable columns={columns} data={genericDataDetail} options={tableOptions} pagination={pagination} onDelete={handleDelete}></CTable>

        </>

    )


}