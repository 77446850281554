import { useEffect, useState } from "react";
import { LogService } from "../../../services/system";
import { PaginationProps } from "../../../components/CTable/model/PaginationProps";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { Icons } from "../../../shared/constant/icon/Icons";
import CTable from "../../../components/CTable/CTable";
import { TableColors } from "../../../shared/constant/color/TableColors";
import { HttpStatusCodes } from "../../../shared/enum/HttpStatusCodes";



export function LatestLogList() {

    const [data, setData] = useState<[]>();
    const [pagination, setPagination] = useState<PaginationProps>();
    const { getAllLatestLog } = LogService();

    const columns: TableColumn<any>[] = [
        { label: "İşlem ID", key: "unique_id", width: 110 },
        { label: "Çağrılan API", key: "controller", width: 100 },
        {
            label: "Durumu",
            key: "status_code",
            width: 110,
            bold: true,
            colorkey: "color",
        },
        { label: "Method", key: "method", width: 120 },
        { label: "IP Adresi", key: "ip", width: 130 },
        { label: "Hata", key: "exception", width: 130 },
        { label: "Hata Detay", key: "exception_message", width: 130 },
        { label: "Oluşturulma Zamanı", key: "created_date_text", width: 100 },
    ];

    const tableOptions = {
        header: "Güncel Log Listesi",
        description: "Son 15 Dakikalık Güncel Log Kayıtları Detaylı Şekilde Yer Alır.",
        columns: columns,
        data: data,
        navigateURL: "edit-demand",
        navigateKey: "id",
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAllLatestLog();

                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');
                let done = false;
                let jsonData = '';

                while (!done) {
                    const { done: streamDone, value } = await reader.read();
                    done = streamDone;
                    jsonData += decoder.decode(value, { stream: true });
                }

                let tmpLogs;
                
                try {
                    tmpLogs = JSON.parse(jsonData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }

                const logs = tmpLogs[0];

                logs.map((item: any) => {
                    const dateObject = new Date(item["rec_date"]);
                    item["created_date_text"] = dateObject.toLocaleString();
                    let color;
                    switch (item.status_code) {
                        case HttpStatusCodes.Success:
                        case HttpStatusCodes.Created:
                            color = TableColors.Success;
                            break;
                        case HttpStatusCodes.BadRequest:
                        case HttpStatusCodes.ServerError:
                            color = TableColors.Danger;
                            break;
                        case HttpStatusCodes.NotFound:
                        case HttpStatusCodes.UnAuthorization:
                            color = TableColors.Warning;
                            break;
                        default:
                            color = TableColors.Secondary;
                            break;
                    }
                    item.color = color;
                });
                setData(logs);

                const paginationData: PaginationProps = {
                    currentPage: (response.currentPage as number) || 1,
                    totalPages: response.totalPages || 1,
                };

                setPagination(paginationData);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    return (<>
        <CTable
            columns={columns}
            data={data}
            options={tableOptions}
            pagination={pagination}
        ></CTable>
    </>)

}