import { isNullOrEmpty } from "../../base/helpers";
import { DemandTypeModel, ServiceResponse } from "../../models";
import { DemandModel } from "../../models/DemandModel";
import useProxyManager from "../core/useProxyManager"

export const DemandTypeService = () => {

    const { httpGet, httpPost, httpPut, httpDelete } = useProxyManager();
    const _basePath = "cms/demand-type/";

    //todo: DemandModel
    const getAllDemandType = async (id: number): Promise<[]> => {
        const data = await httpGet(`${_basePath}all/${id}`);

        //TODO: helper a alınacak
        if (data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getAllLastDemandType = async (): Promise<[]> => {
        const data = await httpGet(_basePath + "all/last");

        //TODO: helper a alınacak
        if (data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getAllLastDemandTypePagination = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const response = await httpGet(`${_basePath}all/last?page?=${page}&pageSize?=${pageSize}`);

        //TODO: helper a alınacak
        if (isNullOrEmpty(response) || response.status !== 200) {
            throw new Error('Not Found')
        }

        return response;
    };

    const getDemandTypeBySpecialName = async (specialName: string): Promise<[]> => {
        const data = await httpGet(`${_basePath}${specialName}`);

        //TODO: helper a alınacak
        if (data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getDemandTypeByMainSpecialName = async (mainSpecialName: string): Promise<[]> => {
        const data = await httpGet(`${_basePath}${mainSpecialName}`);

        //TODO: helper a alınacak
        if (data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const getDemandTypeById = async (reqData: any): Promise<DemandTypeModel> => {
        const response = await httpPost(_basePath + "by", reqData);

        //TODO: helper a alınacak
        if (response === undefined || response === null || response.status !== 200) {
            throw new Error('Not Found')
        }

        return response.data;
    };

    //todo: model eklendikten sonra any yerine model eklenecek
    const getAllDemandTypeById = async (reqData: any): Promise<ServiceResponse> => {
        const data = await httpPost(_basePath + "all/special", reqData);

        //TODO: helper a alınacak
        if (data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const createDemandType = async (reqData: any): Promise<[]> => {
        const data = await httpPost(_basePath + "type", reqData);

        //TODO: helper a alınacak
        if (data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    //todo: model eklendikten sonra any yerine model eklenecek
    const updateDemandType = async (reqData: any): Promise<[]> => {
        const data = await httpPost(_basePath + "update", reqData);

        //TODO: helper a alınacak
        if (data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const deleteDemandType = async (reqData: any): Promise<ServiceResponse> => {
        const response = await httpPost(_basePath + "delete", reqData);

        //TODO: helper a alınacak
        if (response === undefined || response === null || response.status !== 200) {
            throw new Error('Not Found')
        }

        return response;
    };

    return {
        getAllDemandType,
        getAllLastDemandType,
        getAllLastDemandTypePagination,
        getDemandTypeById,
        getDemandTypeBySpecialName,
        getDemandTypeByMainSpecialName,
        getAllDemandTypeById,
        createDemandType,
        updateDemandType,
        deleteDemandType
    };
};

