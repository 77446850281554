import { useEffect } from "react";
import { useParams } from "react-router-dom";

export function UpdateCCStaff() {

    const { id } = useParams();

    const getStaffDetail = async (id: any) => {

    };

    useEffect(() => {

        getStaffDetail(id);


    }, []);

    return (
        <>
            Yakında
        </>
    )
}