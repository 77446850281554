import { isNullOrEmpty } from "../../base/helpers";
import { GenericDataModel, ServiceResponse } from "../../models";
import useProxyManager from "../core/useProxyManager"

export const DemandGenericDataService = () => {

    const { httpGet, httpPost } = useProxyManager();

    const _basePath = "cms/demand-generic-data/";

    const getAllLastGenericData = async (page: number, pageSize: number): Promise<ServiceResponse> => {
        const response = await httpGet(_basePath + "all/last");

        if(isNullOrEmpty(response)) {
            throw new Error('Not Found')
        }

        return response;
    };

    const getAllSubGenericDataById = async (page: number, pageSize: number, reqData: any): Promise<ServiceResponse> => {
        const response = await httpPost(_basePath + "all/last/sub", reqData);

        if(isNullOrEmpty(response)) {
            throw new Error('Not Found')
        }

        return response.data;
    };

    const getSubRelatedDataById = async (page: number, pageSize: number, reqData: any): Promise<GenericDataModel[]> => {
        const response = await httpPost(_basePath + "all/related/sub", reqData);

        if(isNullOrEmpty(response)) {
            throw new Error('Not Found')
        }

        return response.data;
    };

    const createGenericData = async (reqData: any): Promise<ServiceResponse> => {
        const data = await httpPost(_basePath + "data", reqData);

        //TODO: helper a alınacak
        if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }

        return data.data;
    };

    const deleteGenericData = async (reqData: any): Promise<ServiceResponse> => {
        const response = await httpPost(_basePath + "delete", reqData);

        //TODO: helper a alınacak
        if(response === undefined || response === null || response.status !== 200) {
            throw new Error('Not Found')
        }

        return response;
    };

    return {
        getAllLastGenericData,
        getAllSubGenericDataById,
        getSubRelatedDataById,
        createGenericData,
        deleteGenericData
    };
};

