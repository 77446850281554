
import { useState, useEffect } from "react";
import CTable from "../../../components/CTable/CTable";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { DemandModel } from "../../../models";
import { Icons } from "../../../shared/constant/icon/Icons";
import { PaginationProps } from "../../../components/CTable/model/PaginationProps";
import { DemandGenericDataService } from "../../../services/demand-generic-data/demand-generic-data-service";

export function GenericDataList() {

  let [data, setData] = useState<DemandModel[]>();
  const [pagination, setPagination] = useState<PaginationProps>();
  const { getAllLastGenericData, deleteGenericData } = DemandGenericDataService();

  const columns: TableColumn<DemandModel>[] = [
    { label: "Key", key: "special_name", width: 150 },
    { label: "Adı", key: "title", width: 150 },
    { label: "Açıklaması", key: "description", width: 200 },
    { label: "Oluşturulma Tarihi", key: "created_date_text", width: 150 },
    { label: "Güncellenme Tarihi", key: "updated_date_text", width: 150 },
    { label: "Aksiyon", key: undefined, width: 130 },
  ];

  const tableOptions = {
    header: "Ana Veri Tipleri",
    description: "Ana Veri Tipleri Listesi",
    columns: columns,
    data: data,
    icon: Icons.ArrowRight,
    navigateURL: "generic-data-detail",
    navigateKey: "id",
    deleteRowName: "title"
  };


  const handleDelete = async (id: number) => {
    try {

      if(id) {
        const response = await deleteGenericData({ id: id });

        if(response && response.count && response.count > 0) {
          data = data?.filter(x => x.id !== id);
          setData(data);
        } else {
          console.warn("Silme İşlemi Başarısız!");
        }
      }

    } catch (e: any) {
      console.error("Error deleting data:", e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllLastGenericData(1, 10);
        const data = response.data;
        const result = data.filter((x: any) => x['main_special_name'] === null || x['main_special_name'] === undefined || x['main_special_name'] === '');

        //todo: helper a alınacak
        result.map((item: any) => {
          const createdDateObject = new Date(item['created_date']);
          const updatedDateObject = new Date(item['updated_date']);
          item['created_date_text'] = createdDateObject.toLocaleString();
          item['updated_date_text'] = updatedDateObject.toLocaleString();
        });

        setData(result);

        const paginationData: PaginationProps = {
          currentPage: response.currentPage as number || 1,
          totalPages: response.totalPages || 1
        };

        setPagination(paginationData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <CTable columns={columns} data={data} options={tableOptions} pagination={pagination} onDelete={(id) => handleDelete(id)}></CTable>
    </>
  );

}