import { useEffect, useState } from "react";
import { GenericDataModel, ServiceResponse } from "../../../models";
import { useNavigate } from "react-router-dom";
import { DemandGenericDataService } from "../../../services/demand-generic-data/demand-generic-data-service";
import CListBox from "../../../components/CListBox/CListBox";
import React from "react";
import { isNullOrEmpty } from "../../../base/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

export function AddGenericData() {

    const initialValues: GenericDataModel = {
        title: "",
        value: "",
        related_id: undefined,
        order: 0
    };

    const profileDetailsSchema = Yup.object().shape({   
        title: Yup.string().required("Başlık Bilgisi Gereklidir!"),
        value: Yup.string().required("Değer Bilgisi Gereklidir!")
    });

    //related_id: Yup.number().nullable().required("Ana Kayıt Bilgisi Gereklidir!")

    //Hooks
    const [subGenericData, setSubGenericData] = useState<GenericDataModel[]>();
    const [activeBoxID, setActiveBoxID] = useState<any[]>([1]);
    const [activeBoxData, setActiveBoxData] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState("1");

    const [serviceError, setError] = useState<string>();

    const { createGenericData, getAllLastGenericData, getSubRelatedDataById } = DemandGenericDataService();
    const [data, setData] = useState<GenericDataModel>(initialValues);
    const [loading, setLoading] = useState(false);
    const [relatedData, setRelatedData] = useState<boolean>(false);

    const navigate = useNavigate();


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
    }, [activeBoxID]);

    useEffect(() => {
    }, [activeBoxData]);

    const fetchData = async () => {
        try {
            const response = await getAllLastGenericData(1, 1);
            setActiveBoxData(prevData => ({
                ...prevData,
                "1": response.data
            }));
        } catch (e: any) {
            setError(e);
            console.error("Error fetching data:", e);
        }
    };

    const getSubRelatedData = async (id: number) => {
        try {
            const response = await getSubRelatedDataById(1, 1, { "id": id });
            setActiveBoxData(prevData => ({
                ...prevData,
                [id]: response
            }));
        } catch (e: any) {
            setError(e);
            console.error("Error fetching data:", e);
        }
    };

    const handleItemClick = (item: any, listBoxIndex: number) => {
        const newId = item.id;

        formik.setFieldValue('related_id', newId);

        if (!activeBoxID.includes(newId)) {
            setActiveBoxID(prev => {
                prev[listBoxIndex + 1] = item.id
                return prev.filter((id, index) => listBoxIndex + 1 >= index);
            });
            getSubRelatedData(newId);
        }
    };

    const handleClosedClick = (e: any) => {
        if (!isNullOrEmpty(e) && e > 0) {
            formik.setFieldValue('related_id', undefined);
            setActiveBoxID(prev => {
                const updatedIds = prev.filter((id, index) => e > index);
                return updatedIds;
            });
        }
    };

    const formik = useFormik<GenericDataModel>({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            setSubmitting(false);
            setTimeout(() => {
                const updatedData = Object.assign(data, values);
                setData(updatedData);
                setLoading(false);
            }, 1000);

            try {

                const result = await createGenericData(values);

                if (result) {
                    setData(initialValues);
                    navigate('/generic-data-list');
                } else {
                    setError("Veri Ekleme Başarısız!");
                }
            } catch (e: any) {
                console.error('e: ', e);
                setError(e.message);
            }
        },
    });

    return (
        <>
            <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap', width: '100%', display: 'flex' }}>
                {activeBoxID.map((item, index) => {
                    return (
                        <React.Fragment key={`active-box-${index}`}>

                            {activeBoxData[item] &&
                                <CListBox key={`${activeBoxData[item]?.id}-${index}-${index}`}
                                    item={activeBoxData[item]}
                                    listBoxIndex={index}
                                    style={{
                                        display: 'inline-block',
                                        marginRight: '10px',
                                        minWidth: '300px',
                                        height: '300px',
                                        overflowY: 'scroll',
                                        whiteSpace: 'nowrap'
                                    }}
                                    onItemClick={handleItemClick}
                                    onClosedClick={() => handleClosedClick(index)}
                                >
                                </CListBox>
                            }



                            {(activeBoxData[item] === undefined || activeBoxData[item] === null) &&
                                (<>
                                    <CListBox key="Boş" title="Boş" item={item}>

                                    </CListBox>
                                </>)
                            }

                        </React.Fragment>

                    )
                })
                }

            </div>

            {formik.touched.related_id && formik.errors.related_id && (
                <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.related_id}</span>
                </div>
            )}

            <form
                onSubmit={formik.handleSubmit}
                noValidate
                className="form"
                id="create_data"
            >
                <div className="card">
                    <div className="card-header border-0 pt-3">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-7 mb-1">
                                Yeni Veri Tipi Ekle
                            </span>
                            <span className="text-muted mt-1 fw-semibold fs-7"></span>
                        </h3>
                    </div>

                    <div className="collapse show card-footer">
                        <div className="row mb-6">
                            <label className="col-lg-1 col-form-label required fw-bold fs-7">
                                Veri:
                            </label>

                            <div className="col-lg-3 mb-3">
                                <input
                                    type="text"
                                    placeholder="İstanbul"
                                    {...formik.getFieldProps("value")}
                                    className={clsx(
                                        "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                        {
                                            "is-invalid":
                                                formik.touched.value && formik.errors.value,
                                        },
                                        {
                                            "is-valid":
                                                formik.touched.value && !formik.errors.value,
                                        }
                                    )}
                                />
                                {formik.touched.value && formik.errors.value && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.value}</span>
                                    </div>
                                )}
                            </div>

                            <label className="col-lg-2 col-form-label required fw-bold fs-7">
                                Veri Tipi Başlık:
                            </label>

                            <div className="col-lg-3 mb-3">
                                <input
                                    type="text"
                                    placeholder=""
                                    {...formik.getFieldProps("title")}
                                    className={clsx(
                                        "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                                        {
                                            "is-invalid":
                                                formik.touched.title && formik.errors.title,
                                        },
                                        {
                                            "is-valid":
                                                formik.touched.title && !formik.errors.title,
                                        }
                                    )}
                                />
                                {formik.touched.title && formik.errors.title && (
                                    <div className="fv-plugins-message-container">
                                        <span role="alert">{formik.errors.title}</span>
                                    </div>
                                )}
                            </div>

                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                >
                                    {!loading && "Ekle"}
                                    {loading && (
                                        <span
                                            className="indicator-progress"
                                            style={{ display: "block" }}
                                        >
                                            Lütfen Bekleyiniz...{" "}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

}