export const Icons = {
    ArrowRight: "/media/icons/duotune/arrows/arr064.svg",
    Arrow: "",
    Edit: "/media/icons/duotune/art/art005.svg",
    Settings: "/media/icons/duotune/coding/cod001.svg",
    Settings2: "/media/icons/duotune/coding/cod001.svg",
    Close1: "/media/icons/duotune/general/gen034.svg",
    Close2: "/media/icons/duotune/general/gen040.svg",
    Approve1: "/media/icons/duotune/general/gen037.svg",
    Approve2: "/media/icons/duotune/general/gen043.svg",
    Remove: "/media/icons/duotune/general/gen027.svg"
}