import { useEffect, useState } from "react";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import CTable from "../../../components/CTable/CTable";
import { DemandModel } from "../../../models";
import { StaffService } from "../../../services/staff/staff.service";
import { Icons } from "../../../shared/constant/icon/Icons";

export function CallcenterStaffList() {
    let [data, setData] = useState<DemandModel[]>();
    const { getAllStaff, deleteStaff } = StaffService();

    const columns: TableColumn<DemandModel>[] = [
        { label: 'Adı', key: 'name', width: 110 },
        { label: 'Soyadı', key: 'surname', width: 110 },
        { label: 'Email', key: 'email', width: 120 },
        { label: 'Telefon', key: 'phone', width: 130 },
        { label: 'Rolü', key: 'role_id', width: 120 },
        { label: 'Dil', key: 'language', width: 120 },
        { label: 'Tanımlanma Zamanı', key: 'created_date', width: 120 },
        { label: 'Güncellenme Zamanı', key: 'created_date', width: 120 },
        { label: 'Aksiyon', key: undefined, width: 130 },
      ];

    const tableOptions = {
        header: 'Çağrı Merkezi Personel Listesi',
        description: 'Çağrı Merkezi Personel Listesi',
        columns: columns,
        data: data,
        icon: Icons.ArrowRight,
        navigateURL: "update-staff",
        navigateKey: "id",
        deleteRowName: "name"
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await getAllStaff();
              /*response.map((item: any) => {
                tableData.push({ text: item.description});
              });*/
              setData(response);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
    }, []);


    const handleDelete = async (id: number) => {
      try {
  
        if(id) {
          const response = await deleteStaff({ id: id });
  
          if(response && response.count && response.count > 0) {
            data = data?.filter(x => x.id !== id);
            setData(data);
          } else {
            console.warn("Silme İşlemi Başarısız!");
          }
        }
  
      } catch (e: any) {
        console.error("Error deleting data:", e);
      }
    };

    return (
        <>
        <CTable columns={columns} data={data} options={tableOptions} onDelete={(id) => handleDelete(id)}></CTable>
        </>
    )
}