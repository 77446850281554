import { ServiceResponse } from "../../../models";
import useProxyManager from "../../core/useProxyManager"

export const LogService = () => {

    const { httpGetEncrypted } = useProxyManager();

    const getAllLatestLog = async (): Promise<any> => {
        const response = await httpGetEncrypted("cms/log/all-latest");

        //TODO: helper a alınacak
        /*if(data === undefined || data === null || data.status !== 200) {
            throw new Error('Not Found')
        }*/

        return response;
    };

    return {
        getAllLatestLog
    };
};

