import { useState, useEffect } from "react";
import CTable from "../../../components/CTable/CTable";
import { TableColumn } from "../../../components/CTable/model/TableColumn";
import { DemandModel } from "../../../models";
import { Icons } from "../../../shared/constant/icon/Icons";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";
import { PaginationProps } from "../../../components/CTable/model/PaginationProps";
import { Link } from "react-router-dom";

export function DemandTypeList() {
  const [data, setData] = useState<DemandModel[]>();
  const [pagination, setPagination] = useState<PaginationProps>();
  const { getAllLastDemandTypePagination, deleteDemandType } = DemandTypeService();

  const columns: TableColumn<DemandModel>[] = [
    { label: "Ad", key: "title", width: 200 },
    { label: "Alt Adı", key: "subtitle", width: 200 },
    { label: "Açıklaması", key: "detail", width: 250 },
    { label: "Oluşturulma Tarihi", key: "created_date_text", width: 150 },
    { label: "Versiyon", key: "version", width: 50 },
    { label: "Aksiyon", key: undefined, width: 130 },
  ];

  const tableOptions = {
    header: "Talep Tipleri",
    description: "Talep Tipleri Listesi",
    columns: columns,
    data: data,
    icon: Icons.ArrowRight,
    navigateURL: "demand-type-detail",
    navigateKey: "id",
    deleteRowName: "title",
    dataSortingColumn: "created_date",
    navigateEditUrl: "update-demand-type/main",
    navigateEditKey: "id",
  };

  const handleDelete = async (id: number) => {
    try {
      if (id) {
        const response = await deleteDemandType({ id: id });

        if (response && response.count && response.count > 0) {
          setData((prevData) => prevData?.filter((item) => item.id !== id));
        } else {
          alert('Hata: ' + response?.messages);
          console.warn("Silme İşlemi Başarısız!");
        }
      }

    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleEdit = async (id: number) => {
    //console.log('id');
    // if (id) {
    //   <Link
    //     style={{ display: 'none' }}
    //     to={`update-demand-type/main/${id}}`}>
    //   </Link>
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllLastDemandTypePagination(1, 10);
        const result = response.data.filter(x => x['main_special_name'] === null || x['main_special_name'] === undefined || x['main_special_name'] === '');

        //todo: helper a alınacak
        result.map((item: any) => {
          const dateObject = new Date(item['created_date']);
          item['created_date_text'] = dateObject.toLocaleString();
        });

        //desc - todo: CTable içine alınacak
        result.sort((a, b) => {
          return new Date(b["created_date"] as string).getTime() - new Date(a["created_date"] as string).getTime();
        });

        setData(result);

        const paginationData: PaginationProps = {
          currentPage: response.currentPage as number || 1,
          totalPages: response.totalPages || 1
        };

        setPagination(paginationData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);



  return (
    <>
      <CTable columns={columns} data={data} options={tableOptions} pagination={pagination} onDelete={handleDelete} onEdit={handleEdit} ></CTable>
    </>
  );
}
