import { useMemo, useState } from "react";
import { KTSVG } from "../../base/helpers";
import { Icons } from "../../shared/constant/icon/Icons";
import { RibbonProps } from "./model/RibbonProps";
import { CApprovalModal } from "../CApprovalModal/CApprovalModal";
import { Link } from "react-router-dom";

function CRibbon({ title, label, body, children, subItemId, options, onEdit, onDelete }: RibbonProps) {

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteRowId, setDeleteRowId] = useState<number>();
    const [deleteRowName, setDeleteRowName] = useState<string>('');

    const deleteModalOptions = useMemo(() => ({
        header: 'Silme Onayı',
        text: deleteRowId
            ? `${deleteRowId} numaralı kayıt ${deleteRowName} ve altındaki kayıtlar silinecektir, onaylıyor musunuz?`
            : 'Kayıt ve altındaki kayıtlar silinecektir, onaylıyor musunuz?',
        approval_button_title: 'Onayla',
        cancel_button_title: 'Vazgeç',
    }), [deleteRowId]);

    const deleteModalClosed = () => {
        setShowDeleteModal(false);
        setDeleteRowId(undefined);
        setDeleteRowName('');
    };

    const deleteModalApproved = () => {
        setShowDeleteModal(false);
        if (onDelete && deleteRowId) {
            onDelete(deleteRowId);
        }
    };

    return (
        <>
            <div className="card card-bordered">
                <div className="card-header justify-content-end ribbon ribbon-start ribbon-clip">
                    <div className="ribbon-label">
                        {label}
                        <span className="ribbon-inner bg-success"></span>
                    </div>
                    <div className="card-title">
                        {title} |
                        <div style={{ marginLeft: 5 }}>
                            {onEdit && (<>
                                <Link
                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                    to={`/${options.navigateEditUrl}/${subItemId}`}>
                                    <KTSVG
                                        path={Icons.Edit}
                                        className="svg-icon-2"
                                    />
                                </Link>
                            </>)}
                            <a
                                href="#"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setDeleteRowId(subItemId as number);
                                    setDeleteRowName(label ?? '');
                                    setShowDeleteModal(true);
                                }}
                            >
                                <KTSVG
                                    path={Icons.Remove}
                                    className="svg-icon-3"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {body}
                    {children}
                </div>
            </div>

            <CApprovalModal
                show={showDeleteModal}
                options={deleteModalOptions}
                onClosedClick={() => deleteModalClosed()}
                onApprovedClick={() => deleteModalApproved()}
            />
        </>
    )

}

export default CRibbon;
