import React from "react";
import { KTSVG } from "../../base/helpers";


function CListBox({ title, item, listBoxIndex, classname, style, onItemClick, onClosedClick }: any) {

    return (
        <React.Fragment>
            <div className={`${classname} card card-xl-stretch mb-xl-8`} style={style}>
                {/* begin::Header */}
                <div className='card-header border-0' style={{ minHeight: '30px'}}>
                    <h3 className='card-title fw-bold text-dark'>{title}</h3>
                    <div className='card-toolbar'>
                        {/* begin::Menu */}
                        {listBoxIndex !== 0 &&
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                onClick={() => onClosedClick()}
                            >
                                <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-2' />
                            </button>
                        }
                        {/* <Dropdown1 /> */}
                        {/* end::Menu */}
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-2'>
                    {item && item.length > 0 &&
                        item.map((childItem: any, index: any) => {
                            return (
                                <React.Fragment key={`key-list-box-${childItem?.title}${index}`}>
                                    {/* begin::Item */}
                                    <div className='d-flex align-items-center mb-8'>
                                        {/* begin::Bullet */}
                                        <span className='bullet bullet-vertical h-40px bg-success'></span>
                                        {/* end::Bullet */}
                                        {/* begin::Description */}
                                        <div className='mx-5 flex-grow-1'>
                                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'
                                                onClick={() => onItemClick(childItem, listBoxIndex)}>
                                                {childItem?.title}
                                            </a>
                                        </div>
                                    </div>
                                    {/* end:Item */}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                {/* end::Body */}
            </div>
        </React.Fragment >
    )


}

export default CListBox;