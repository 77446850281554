import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DemandTypeModel } from "../../../models";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { DemandTypeDataType } from "../../../shared/enum/DemandType/DemandTypeDataType";
import React from "react";
import CListBox from "../../../components/CListBox/CListBox";
import { DemandGenericDataService } from "../../../services/demand-generic-data/demand-generic-data-service";
import { isNullOrEmpty } from "../../../base/helpers";

export function AddDemandType() {

  const initialValues: DemandTypeModel = {
    title: "",
    detail: "",
    special_name: "",
    main_special_name: "",
    data_type: "string",
    valid_data_type: [],
    regexp: "",
    is_optional: false,
    created_date: new Date(),
    updated_date: new Date(),
    version: 1,
  };

  //Hooks
  const [demandTypeData, setdemandTypeData] = useState<DemandTypeModel[]>();
  const { getAllLastDemandType } = DemandTypeService();
  const { createGenericData, getAllLastGenericData, getSubRelatedDataById } = DemandGenericDataService();

  const [selectedValue, setSelectedValue] = useState("1");
  const [selectedIsOptional, setIsOptional] = useState<number>();

  const [formData, setformData] = useState<DemandTypeModel>();
  const [serviceError, setError] = useState<string>();

  const { createDemandType } = DemandTypeService();
  const [data, setData] = useState<DemandTypeModel>(initialValues);
  const [loading, setLoading] = useState(false);
  const [fileDocumentType, setFileDocumentType] = useState<boolean>(false);
  const [isDropdown, setDropdownType] = useState<boolean>(false);
  const [controlRules, setControlRules] = useState<boolean>(false);
  const [activeBoxID, setActiveBoxID] = useState<any[]>([1]);
  const [activeBoxData, setActiveBoxData] = useState<any[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    //setIsOptional(0);
  }, []);

  //
  const selectChange = (e: any) => {
    formik.setFieldValue('main_special_name', e.target.value);
    formik.setFieldValue('special_name', e.target.value);
  };

  const selectChangeDemandType = (e: any) => {
    setSelectedValue(e.target.value);
    if (parseInt(e.target.value) === 2) {
      fetchData();
    }
  };

  const selectChangeDataType = (e: any) => {
    const gelenDataType = parseInt(e.target.value);
    let dataType;

    if (gelenDataType === DemandTypeDataType.File) {
      setFileDocumentType(true);
      setDropdownType(false);
      formik.setFieldValue('generic_data_id', null);
    } else if (gelenDataType === DemandTypeDataType.Dropdown) {
      setDropdownType(true);
      setFileDocumentType(false);
    } else {
      setDropdownType(false);
      setFileDocumentType(false);
      formik.setFieldValue('generic_data_id', null);
    }

    // Formik handleChange fonksiyonunu çağırarak formik'e seçilen değeri güncelletiyoruz.
    formik.handleChange(e);
    formik.setFieldValue('data_type', gelenDataType);
  };

  const selectChangeControlRules = (e: any) => {
    formik.setFieldValue('regexp', e.target.value);
  };

  const setAndValidformData = () => {
    const dmy = initialValues;
    setformData(dmy);
  };

  const fetchData = async () => {
    try {
      const response = await getAllLastDemandType();
      const result = response.filter((x) => x["main_special_name"] === null);

      //todo: helper a alınacak
      result.map((item: any) => {
        const dateObject = new Date(item["created_date"]);
        item["created_date_text"] = dateObject.toLocaleString();
      });

      setdemandTypeData(result);

      try {
        const response = await getAllLastGenericData(1, 1);
        setActiveBoxData(prevData => ({
          ...prevData,
          "1": response.data
        }));
      } catch (e: any) {
        setError(e);
        console.error("Error fetching data:", e);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const profileDetailsSchema = Yup.object().shape({
    title: Yup.string().required("Başlık Bilgisi Gereklidir!"),
    detail: Yup.string().required("Açıklama Bilgisi Gereklidir!"),
  });

  const updateData = (fieldsToUpdate: Partial<DemandTypeModel>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate);
    setData(updatedData);
  };

  const formik = useFormik<DemandTypeModel>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setSubmitting(false);
      setTimeout(() => {
        const updatedData = Object.assign(data, values);
        setData(updatedData);
        setLoading(false);
      }, 1000);

      try {
        let dataType;

        switch (parseInt(values.data_type ?? '0')) {
          case DemandTypeDataType.Number:
            dataType = "number";
            break;
          case DemandTypeDataType.String:
            dataType = "string";
            break;
          case DemandTypeDataType.Boolean:
            dataType = "boolean";
            break;
          case DemandTypeDataType.File:
            dataType = "file";
            break;
          case DemandTypeDataType.Dropdown:
            dataType = "dropdown";
            break;
          default:
            break;
        }

        values.data_type = dataType;

        //Ana Talepse boş gitmeli.
        if (selectedValue === "1") {
          values.main_special_name = undefined;
        }

        const result = await createDemandType(values);
        if (result) {
          setData(initialValues);
          navigate('/demand-type-list');
        } else {
          setError("Tip Ekleme Başarısız!");
        }
      } catch (e: any) {
        console.error('e: ', e);
        setError(e.message);
      }
    },
  });

  const getSubRelatedData = async (id: number) => {
    try {
      const response = await getSubRelatedDataById(1, 1, { "id": id });
      console.log("response|getSubRelatedDataById:", response);
      setActiveBoxData(prevData => ({
        ...prevData,
        [id]: response
      }));
    } catch (e: any) {
      setError(e);
      console.error("Error fetching data:", e);
    }
  };

  const handleItemClick = (item: any, listBoxIndex: number) => {
    console.log("Tıklanan öğe:", item);
    console.log("Tıklanan listBoxIndex:", listBoxIndex);
    const newId = item.id;

    formik.setFieldValue('generic_data_id', newId);

    if (!activeBoxID.includes(newId)) {
      setActiveBoxID(prev => {
        prev[listBoxIndex + 1] = item.id
        return prev.filter((id, index) => listBoxIndex + 1 >= index);
      });
      console.log('ActiveBoxID: ', activeBoxID);
      getSubRelatedData(newId);
    }
  };

  const handleClosedClick = (e: any) => {
    console.log('closed: ', e);
    if (!isNullOrEmpty(e) && e > 0) {
      setActiveBoxID(prev => {
        const updatedIds = prev.filter((id, index) => e > index);
        return updatedIds;
      });
      // setActiveBoxData(prevData =>
      //     prevData.filter(item => item.id !== id)
      // );
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Yeni Talep Tipi Ekle
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7"></span>
          </h3>
        </div>
        <div className="card-body py-3">
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              Eklenecek Talep Tipi
            </label>

            <div className="col-lg-8 fv-row">
              <select
                className="form-select form-select-solid form-select-lg"
                value={selectedValue}
                onChange={selectChangeDemandType}
              >
                <option value="1">Ana Talep</option>
                <option value="2">Alt Talep</option>
              </select>

              <div className="fv-plugins-message-container">
                <div className="fv-help-block"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="collapse show card-footer">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="form"
            id="create_demand_type"
          >
            <div className="card-body py-3">
              {selectedValue === "1" && (
                <>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Talep Başlık:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder="Denklik Başvurusu"
                            {...formik.getFieldProps("title")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.title && formik.errors.title,
                              },
                              {
                                "is-valid":
                                  formik.touched.title && !formik.errors.title,
                              }
                            )}
                          />
                          {formik.touched.title && formik.errors.title && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.title}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Detay Bilgi:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <textarea
                            placeholder="Denklik Başvurusu ...."
                            rows={4}
                            {...formik.getFieldProps("detail")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.detail &&
                                  formik.errors.detail,
                              },
                              {
                                "is-valid":
                                  formik.touched.detail &&
                                  !formik.errors.detail,
                              }
                            )}
                          />
                          {formik.touched.detail && formik.errors.detail && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.detail}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {selectedValue === "2" && (
                <>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Mevcut Talep Tipi
                    </label>

                    <div className="col-lg-4">
                      <select
                        {...formik.getFieldProps("main_special_name")}
                        onChange={selectChange}
                        className={clsx(
                          "form-select form-select-solid form-select-lg mb-3 mb-lg-0",
                          {
                            "is-invalid":
                              formik.touched.main_special_name && formik.errors.main_special_name,
                          },
                          {
                            "is-valid":
                              formik.touched.main_special_name && !formik.errors.main_special_name,
                          }
                        )}
                      >
                        <option value="" disabled></option>
                        {demandTypeData &&
                          demandTypeData?.map((item, index) => {
                            return (
                              <React.Fragment key={'fragment' + index + item?.special_name}>
                                <option key={'option' + index + item?.special_name} value={item.special_name}>{item.title}</option>
                              </React.Fragment>
                            );
                          })}
                      </select>

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Alt Tip Başlık:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder="TC Kimlik No"
                            {...formik.getFieldProps("title")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.title && formik.errors.title,
                              },
                              {
                                "is-valid":
                                  formik.touched.title && !formik.errors.title,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Alt Tip Detaylar:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <textarea
                            placeholder="ör: TC Kimlik numarası bilgisi resmi kurumlar için gereklidir."
                            rows={4}
                            {...formik.getFieldProps("detail")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.detail && formik.errors.detail,
                              },
                              {
                                "is-valid":
                                  formik.touched.detail && !formik.errors.detail,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Veri Tipi:
                    </label>

                    <div className="col-lg-4 fv-row">
                      <select
                        {...formik.getFieldProps("data_type")}
                        onChange={selectChangeDataType}
                        className={clsx(
                          "form-select form-select-solid form-select-lg",
                          {
                            "is-invalid":
                              formik.touched.data_type && formik.errors.data_type,
                          },
                          {
                            "is-valid":
                              formik.touched.data_type && !formik.errors.data_type,
                          }
                        )}
                      >
                        <option value={DemandTypeDataType.None}>Seçiniz</option>
                        <option value={DemandTypeDataType.Number}>Sayı</option>
                        <option value={DemandTypeDataType.String}>Metin</option>
                        <option value={DemandTypeDataType.File}>Dosya</option>
                        <option value={DemandTypeDataType.Dropdown}>Veri</option>
                      </select>

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block"></div>
                      </div>
                    </div>
                  </div>

                  {isDropdown && (
                    <>
                      <div style={{
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                        width: '100%',
                        display: 'flex'
                      }}>
                        {activeBoxID.map((item, index) => {
                          console.log('activeBoxData: ', activeBoxData)
                          console.log('item: ', item)
                          console.log('aaa1: ', activeBoxData[item], ' item: ', item, 'index: ', index)
                          return (
                            <React.Fragment key={`active-box-${index}`}>

                              {activeBoxData[item] &&

                                <CListBox key={`${activeBoxData[item]?.id}-${index}-${index}`}
                                  item={activeBoxData[item]}
                                  listBoxIndex={index}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '10px',
                                    minWidth: '300px',
                                    height: '300px'
                                  }}
                                  onItemClick={handleItemClick}
                                  onClosedClick={() => handleClosedClick(index)}
                                >
                                </CListBox>

                              }

                              {(activeBoxData[item] === undefined || activeBoxData[item] === null) &&
                                (<>
                                  <CListBox key="Boş" title="Boş" item={item}>

                                  </CListBox>
                                </>)
                              }

                            </React.Fragment>
                          )
                        })
                        }

                      </div>
                    </>
                  )}

                  {fileDocumentType && <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      İzin Verilen Doküman Tipleri:
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder=".pdf, .docx, .xls"
                            {...formik.getFieldProps("valid_data_type")}
                            className={clsx(
                              "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                              {
                                "is-invalid":
                                  formik.touched.valid_data_type && formik.errors.valid_data_type,
                              },
                              {
                                "is-valid":
                                  formik.touched.valid_data_type && !formik.errors.valid_data_type,
                              }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>}

                  {controlRules && <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Kontrol Edilecek Kurallar:
                    </label>

                    <div className="col-lg-4 fv-row">
                      <select
                        {...formik.getFieldProps("regexp")}
                        value={selectedValue}
                        onChange={selectChangeControlRules}
                        className={clsx(
                          "form-select form-select-solid form-select-lg",
                          {
                            "is-invalid":
                              formik.touched.regexp && formik.errors.regexp,
                          },
                          {
                            "is-valid":
                              formik.touched.regexp && !formik.errors.regexp,
                          }
                        )}
                      >
                        <option value="12">
                          Sayı Kontrol (11 rakamdan büyük olamaz)
                        </option>
                        <option value="22">
                          Tarih Kontrol (Doğum tarihi 2000 den büyük olmalıdır!)
                        </option>
                      </select>

                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block"></div>
                      </div>
                    </div>
                  </div>}

                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Zorunlu Alan
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-6 fv-row">
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input
                              id="flexSwitchDefault"
                              type="checkbox"
                              {...formik.getFieldProps("is_optional")}
                              value={selectedIsOptional}
                              className={clsx(
                                "form-check-input h-25px w-12px",
                                {
                                  "is-invalid":
                                    formik.touched.is_optional && formik.errors.is_optional,
                                },
                                {
                                  "is-valid":
                                    formik.touched.is_optional && !formik.errors.is_optional,
                                }
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {!loading && "Ekle"}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Lütfen Bekleyiniz...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
          <div>
            {serviceError && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">Hata: {serviceError}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
