

function CSeparator({ color, size } : any) {
    

    return (
        <>
            <div className={`separator border-${color} my-${size}`}>
            </div>
        </>
    )

}

export default CSeparator;
