/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../base/helpers'
import { Icons } from '../../shared/constant/icon/Icons'

type Props = {
  show: boolean,
  options: any,
  onClosedClick: () => void,
  onApprovedClick: () => void,
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CApprovalModal = ({ show, options, onClosedClick, onApprovedClick }: Props) => {

  const prevStep = () => {
    onClosedClick();
  }

  const nextStep = () => {
    onApprovedClick();
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'//todo hata veriyor bakılacak
      // inert="true"
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={onClosedClick}
    >
      <div className='modal-header'>
        <h2>{options?.header}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onClosedClick}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5'>
          <p>{options?.text}</p>
          <br />
          {/*begin::Actions */}
          <div className='d-flex flex-stack pt-10 justify-content-end'>
            <button
              type='button'
              className='btn btn-lg btn-light me-3'
              data-kt-stepper-action='previous'
              onClick={prevStep}
            >
              {options?.cancel_button_title ?? 'Vazgeç'}
              <KTSVG
                path={Icons.Close2}
                className='svg-icon-3 me-1'
              />{' '}
            </button>

            <button
              type='button'
              className='btn btn-lg btn-danger'
              data-kt-stepper-action='next'
              onClick={nextStep}
            >
              {options?.approval_button_title}
              <KTSVG
                path={Icons.Approve1}
                className='svg-icon-3 ms-1 me-0'
              />
            </button>
          </div>
          {/*end::Actions */}

        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { CApprovalModal }
