import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DemandTypeService } from "../../../services/demand-type/demand-type.service";
import clsx from "clsx";
import CRibbon from "../../../components/CRibbon/CRibbon";
import CSeparator from "../../../components/CSeparator/CSeparator";


export function DemandTypeDetail() {

    const { id } = useParams();
    const { getAllDemandTypeById, deleteDemandType } = DemandTypeService();
    const [demandTypeList, setDemandTypeList] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getAllDemandType = async (id: any) => {
            const data = await getAllDemandTypeById({ "main_special_id": id });
            setDemandTypeList(data);
        };
        getAllDemandType(id);


    }, [id]);

    function getTimeString(date: Date) {
        if (date !== undefined && date !== null) {
            return date.toLocaleString();
        } else {
            return "";
        }
    }

    function convertDataTypeText(dataType: string) {
        let convertedText;

        switch (dataType) {
            case "file":
                convertedText = "Dosya";
                break;
            case "string":
                convertedText = "Metin";
                break;
            case "number":
                convertedText = "Sayı";
                break;
            case "dropdown":
                convertedText = "Veri";
                break;
            default:
                break;
        }

        return convertedText;
    };


    const handleDelete = async (id: number) => {
        try {
            if (id) {
                const response = await deleteDemandType({ id: id });

                if (response && response.count && response.count > 0) {
                    setDemandTypeList((prevData: any[]) => prevData?.filter((item) => item.id !== id));
                } else {
                    alert('Hata: ' + response?.messages);
                    console.warn("Silme İşlemi Başarısız!");
                }
            }

        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const handleEdit = async (id: number) => {
        try {
            if (id) {
                console.log('id: ', id);
                // <Link
                //     style={{ display: 'none' }}
                //     to={`"/" +${id}}`}>
                // </Link>
                navigate(`update-demand-type/sub/${id}`);
            }

        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const ribbonOptions = {
        navigateEditUrl: "update-demand-type/sub",
        navigateEditKey: "id",
    };

    return (
        <>
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            Talep Tipi Detayları
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                </div>
                <div className="collapse show card-footer">
                    <div className="card-body py-3">
                        {demandTypeList && demandTypeList.length > 0 &&
                            demandTypeList.map((item: any) => {
                                return (
                                    <>
                                        <div className="row mb-4">
                                            <CRibbon
                                                title={convertDataTypeText(item.data_type)}
                                                label={item.title}
                                                body={item.description}
                                                subItemId={item.id}
                                                options={ribbonOptions}
                                                onEdit={(id) => handleEdit(id)}
                                                onDelete={(id) => handleDelete(id)}
                                            >
                                                <div className="row mb-1">
                                                    <div className="col-lg-7">
                                                        <div className="col-lg-9 col-form-label fs-7">
                                                            <label className="col-lg-3 fw-bold fv-row">
                                                                Açıklama:
                                                            </label>
                                                            <label className="col-lg-9 fv-row">
                                                                {item.detail}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <CSeparator color="primary" size="5" />

                                                <div className="row mb-1">
                                                    <div className="col-lg-7">
                                                        <div className="col-lg-9 col-form-label fs-7">
                                                            <label className="col-lg-3 fw-bold fv-row">
                                                                Hata Mesajı:
                                                            </label>
                                                            <label className="col-lg-9 fv-row">
                                                                {item.error_message}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <CSeparator color="primary" size="5" />

                                                <div className="row mb-1">
                                                    <div className="col-lg-7">
                                                        <div className="col-lg-9 col-form-label fs-7">
                                                            <label className="col-lg-3 fw-bold fv-row">
                                                                Versiyon:
                                                            </label>
                                                            <label className="col-lg-3 fv-row">
                                                                {item.version}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <CSeparator color="primary" size="5" />

                                                <div className="row mb-1">
                                                    <div className="col-lg-8">
                                                        <div className="col-lg-9 col-form-label fs-7">
                                                            <label className="col-lg-3 fw-bold fv-row">
                                                                Oluşturulma Tarihi:
                                                            </label>
                                                            <label className="col-lg-3 fv-row">
                                                                {item.created_date ? getTimeString(new Date(item.created_date)) : ''}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <CSeparator color="primary" size="5" />

                                                <div className="row mb-1">
                                                    <div className="col-lg-8">
                                                        <div className="col-lg-9 col-form-label fs-7">
                                                            <label className="col-lg-3 fw-bold fv-row">
                                                                Güncellenme Tarihi:
                                                            </label>
                                                            <label className="col-lg-3 fv-row">
                                                                {item.updated_date ? getTimeString(new Date(item.updated_date)) : ''}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <CSeparator color="primary" size="5" />

                                                <div className="row mb-2">
                                                    <div className="col-lg-6 fv-row">
                                                        <div className="form-check form-switch form-check-custom form-check-solid">
                                                            <label className="col-lg-3 fw-bold col-form-label fs-7">
                                                                Zorunlu Alan
                                                            </label>
                                                            <input
                                                                id="flexSwitchDefault"
                                                                type="checkbox"
                                                                disabled
                                                                checked={item.is_optional}
                                                                className={clsx(
                                                                    "form-check-input h-25px w-12px"
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </CRibbon>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}